.component-forgot-password{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
    width: 40%;

    .title {
        font-size: calc(14px + 2vmin);
    }

    .fieldCell {
        width: 75%;
        margin: 15px auto;
        display: flex;
        flex-direction: column;


        span {
            margin: auto auto auto 0;
        }
    
        input {
            margin: auto;
            height: 40px;
            font-size: calc(6px + 2vmin);
            border-radius: 10px;
            
            :focus {
                border: 0;
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        margin: 15px auto;

        button {
            width: 25%;
            margin: auto;
        }
    }
}