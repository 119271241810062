.component-error{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 15px auto;
    padding: 10px 15px;
    border-radius: 15px;

    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

    .error-title {
        font-weight: bold;
        margin: 5px auto;
    }

    .error-text {
        margin: 10px auto;
    }
}