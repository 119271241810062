.App {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100vh;
}
.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: rgb(230, 230, 230);
  color: rgb(156, 156, 156);
}

