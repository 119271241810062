.component-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;

    margin: auto;

    .component-header {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 20px;
        .title {
            font-size: calc(6px + 2vmin);
        }
    }

    .button-submit {
        width: fit-content;
    }
    

    .line {
        width: 80%;
        height: 2px;
        background-color: #c7c7c7;
        margin: 15px auto;
    }

    .list {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 40vmin;
        overflow-y: auto;
        margin: auto;
        font-size: calc(2px + 2vmin);

        .requestCell {
            display: flex;
            flex-direction: row;
            width: fit-content;
            justify-content: space-around;
            margin: 10px auto;
        }
    }
}