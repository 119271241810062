.list-field-cell {
    .list-cell {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px auto;

        span {
            width: 30%;
            text-align: center;
            margin: 5px 5px;
        }

        input {
            width: 30%;
            margin: 8px;
            border: 1px solid #ced4da;
            border-radius: .25rem;
        }
    }
}

.add-icon {
    font-size: 25px;
    margin: auto;
}

.custom-dialog .modal-dialog {
    margin: 15% 20%;
}

.modal-dialog .modal-content {
    width: 100vmin;
    margin: auto;
}

.list-cell .react-datepicker-wrapper {
    margin: 5px 5px;

    .react-datepicker__input-container input {
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        padding: 0 15px;
        margin: auto;
        font-size: 1rem;
        height: calc(1.5em + .75rem + 2px);
    }
}
 
