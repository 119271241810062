.field-cell .date-selection-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    span {
        font-size: calc(4px + 1vmin);
        width: 20%;
        margin: auto 5px;
    }

    input {
        margin: auto;
        width: 80%;
        padding: 0px 10px;
        font-size: calc(4px + 1vmin);
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }
}

.react-datepicker-wrapper {
    width: 30%;
    margin: auto;
}