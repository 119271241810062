.component-type-selection {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  text-align: center;

  .form-content {
    display: flex;
    flex-direction: column;

    .title {
      font-size: calc(14px + 2vmin);
    }

    select {
      margin: 15px auto;
      width: 60%;
    }

    button {
      width: 30%;
      margin: 15px auto;
    }

    .description-content {
      height: 30vmin;
      overflow-y: auto;
    }
  }
}
