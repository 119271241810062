.component-navbar{
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: calc(4px + 2vmin);
    .buttons{
        margin-top: 25px;
    }
    span {
        margin: auto auto auto 15px;
    }

    button {
        max-height: 35px;
        margin: auto 15px auto auto;
    }
    div {
        justify-content: space-between;
    }
}