.component-create {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: calc(4px + 1vmin);
    margin: auto;
    width: 600px;

    .field-cell {
        display: flex;
        flex-direction: row;

        margin: 10px 0;

        span {
            margin: auto;
            width: 30%;
        }

        input {
            margin: auto;
            width: 70%;
        }
    }

    .field-checkbox {
        display: flex;
        flex-direction: row;

        margin: 10px 0;

        span {
            margin: auto;
            width: 30%;
        }

        input {
            margin-right: 51%;
            width: 70%;
        }
    }

    .line {
        width: 80%;
        height: 2px;
        background-color: #c7c7c7;
        margin: 15px auto;
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 10px 0;

        button {
            margin: auto 10px;
        }
    }
}